import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Dropdown, DropdownButton, ButtonToolbar, SplitButton } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          The purpose of a dropdown is to provide the user a list of similarly grouped actions that they can perform. All of these actions will be grouped under one top level category, which will be the name of dropdown button.
        </p>
        <p>
          At smaller screen sizes, the dropdown component will group tabs or horizontally laid out menu items to save more screen space.
        </p>
        <p>
          Visually, dropdowns are very similar to buttons, but perform differently in terms of functionality. Dropdown can also be used as menus.
        </p>
      </>
    );

    var codeString = `
    // Default Dropdowns
    <DropdownButton title="Primary" variant="primary" id="dropdown-variants-primary" key="Primary">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
      <Dropdown.Item eventKey="3" active>
        Active Item
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
    </DropdownButton>
    <DropdownButton title="Secondary" variant="secondary" id="dropdown-variants-secondary" key="Secondary">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    </DropdownButton>
    <DropdownButton title="Success" variant="success" id="dropdown-variants-success" key="Success">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    </DropdownButton>
    <DropdownButton title="Info" variant="info" id="dropdown-variants-info" key="Info">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    </DropdownButton>
    <DropdownButton title="Warning" variant="warning" id="dropdown-variants-warning" key="Warning">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    </DropdownButton>
    <DropdownButton title="Danger" variant="danger" id="dropdown-variants-danger" key="Danger">
      <Dropdown.Item eventKey="1">Action</Dropdown.Item>
    </DropdownButton>

    // Dropdowns with Icons
    <ButtonToolbar>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Primary</span>
      } variant="primary" id="dropdown-variants-primary" key="Primary">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
          Active Item
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
      </DropdownButton>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Secondary</span>
      } title="Secondary" variant="secondary" id="dropdown-variants-secondary" key="Secondary">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      </DropdownButton>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Success</span>
      } variant="success" id="dropdown-variants-success" key="Success">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      </DropdownButton>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Info</span>
      } variant="info" id="dropdown-variants-info" key="Info">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      </DropdownButton>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Warning</span>
      } variant="warning" id="dropdown-variants-warning" key="Warning">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      </DropdownButton>
      <DropdownButton title={
        <span><i className="fa fa-check"></i> Danger</span>
      }  variant="danger" id="dropdown-variants-danger" key="Danger">
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
      </DropdownButton>
    </ButtonToolbar>

    // Split Buttons
    <ButtonToolbar>
      <SplitButton title="Primary" className="split-button" target="_blank" variant="primary" size="sm" id="dropdown-split-variants-primary" key="primary">
        <Dropdown.Item>Action</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="1">
          <i className="fa fa-pencil"></i> Edit Favorite Formats
        </Dropdown.Item>
      </SplitButton>
      <SplitButton title="Secondary" className="split-button" target="_blank" variant="secondary" size="sm" id="dropdown-split-variants-secondary" key="secondary">
        <Dropdown.Item>Action</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="1">
          <i className="fa fa-pencil"></i> Edit Favorite Formats
        </Dropdown.Item>
      </SplitButton>
    </ButtonToolbar>
    `;

    var code = (
      <>
        <br />
        <h4>Default</h4>
        <ButtonToolbar>
          <DropdownButton title="Primary" variant="primary" id="dropdown-variants-primary" key="Primary">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3" active>
              Active Item
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title="Secondary" variant="secondary" id="dropdown-variants-secondary" key="Secondary">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title="Success" variant="success" id="dropdown-variants-success" key="Success">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title="Info" variant="info" id="dropdown-variants-info" key="Info">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title="Warning" variant="warning" id="dropdown-variants-warning" key="Warning">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title="Danger" variant="danger" id="dropdown-variants-danger" key="Danger">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
        </ButtonToolbar>

        <br />
        
        <h4>Dropdowns with Icons</h4>
        <ButtonToolbar>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Primary</span>
          } variant="primary" id="dropdown-variants-primary" key="Primary">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3" active>
              Active Item
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Secondary</span>
          } variant="secondary" id="dropdown-variants-secondary" key="Secondary">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Success</span>
          } variant="success" id="dropdown-variants-success" key="Success">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Info</span>
          } variant="info" id="dropdown-variants-info" key="Info">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Warning</span>
          } variant="warning" id="dropdown-variants-warning" key="Warning">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
          <DropdownButton title={
            <span><i className="fa fa-check"></i> Danger</span>
          }  variant="danger" id="dropdown-variants-danger" key="Danger">
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          </DropdownButton>
        </ButtonToolbar>

        <br />

        <h4>Split Buttons</h4>
        <p>Split buttons should be used when an action can have a primary action but also a dropdown. An example is a split download button, where a user can be directed to a download page, but also "quick download" specific formats.</p>
        <ButtonToolbar>
          <SplitButton title="Primary" className="split-button" target="_blank" variant="primary" size="sm" id="dropdown-split-variants-primary" key="primary">
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="1">
              <i className="fa fa-pencil"></i> Edit Favorite Formats
            </Dropdown.Item>
          </SplitButton>
          <SplitButton title="Secondary" className="split-button" target="_blank" variant="secondary" size="sm" id="dropdown-split-variants-secondary" key="secondary">
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="1">
              <i className="fa fa-pencil"></i> Edit Favorite Formats
            </Dropdown.Item>
          </SplitButton>
        </ButtonToolbar>

        <br />
        
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Buttons, Links, & Clickable Elements Should Have Accessible Labels That Match</h5>
        <p>Accessible labels for links, buttons, and clickable elements should include any visible text.</p>
        <h5>Why</h5>

        <p>
          There shouldn't be a difference in how users interact with buttons and clickable elements. Buttons with a
          visual component (like "Get Started" or "Submit") should have a matching programmatic name.
        </p>

        <p>
          This ensures that all users will have the same experience. A screen reader user would have the text "Get
          Started" read out to them; a sighted user would read "Get Started"; and a speech-to-text user would speak "Get
          Started."
        </p>

        <p>
          Contradictory labels (i.e., a button with visual text "Get Started" and programmatic label "Sign Up") are
          extremely confusing for users, and are particularly challenging for any users with cognitive disabilities.
        </p>

        <h5>Best Practices</h5>
        <ul>
          <li>
            Conventionally, the label for user interface components is the adjacent text string. The typical positioning
            of the label is inside buttons and tabs or immediately below icons serving as buttons.
          </li>
          <li>
            It's not necessary to include symbols (e.g., the colon in "First Name:") in labels. "First Name" would be
            sufficient.
          </li>
          <li>`aria-label` and `aria-labelledby` override native label semantics</li>
          <li>
            If the visible button text is a symbol (for example, an icon of a list), the accessible label should
            indicate the function of the icon ("bulleted list")
          </li>
        </ul>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Dropdowns"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
